import * as download from 'downloadjs';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import * as moment from 'moment';
import { getAccessToken } from '../Auth/Auth';
import callExport from '../../utils/callExport';
import * as actions from './actions';
import { logError } from '../../utils/logger';

function* handleFetchEUTopics(action: any): any {
  const ids = action.payload.export ? `?ids=${action.payload.export.join(',')}` : '';
  try {
    const res = yield call(
      callExport,
      'get',
      process.env.REACT_APP_SEARCH_API_URL || '',
      `/v2/export/eu_topics${ids}`,
    );

    if (res) {
      download(res, 'export-topics-' + moment().format('YYYY-MM-DD') + '.pptx');
    }
  } catch (err) {
    logError('error', err);
  }
}


function* handleAIFetchEUTopics(action: any): any {
  const ids = action.payload.export ? `?ids=${action.payload.export.join(',')}` : '';
  try {
    const res = yield call(
      callExport,
      'get',
      process.env.REACT_APP_SEARCH_API_URL || '',
      `/v3/export/eu_topics${ids}`,
    );

    if (res) {
      download(res, 'export-topics-' + moment().format('YYYY-MM-DD') + '.pptx');
    }
  } catch (err) {
    logError('error', err);
  }
}

function* handleFetchNationalTopics(action: any): any {
  const ids = action.payload.export ? `?ids=${action.payload.export.join(',')}` : '';
  try {
    const res = yield call(
      callExport,
      'get',
      process.env.REACT_APP_SEARCH_API_URL || '',
      `/v2/export/national${ids}`,
    );

    if (res) {
      download(res, 'export-topics-' + moment().format('YYYY-MM-DD') + '.pptx');
    }
  } catch (err) {
    logError('error', err);
  }
}

function* handleExcelFetch() {
  try {
    yield fetch((process.env.REACT_APP_SEARCH_API_URL || '') + '/api/v2/export/saved-excel', {
      method: 'GET',
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    })
      .then((res) => res.blob())
      .then((blob) => window.URL.createObjectURL(blob))
      .then((url) => {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `export-saved-${moment().format('YYYY-MM-DD')}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });

    yield put(actions.exportExcelRequestSuccess());
  } catch (err) {
    logError('error', err);
  }
}

function* watchExportEUTopicsRequest() {
  yield takeEvery(actions.ExportActionTypes.EXPORT_REQUEST_EU_TOPICS, handleFetchEUTopics);
}


function* watchExportAIEUTopicsRequest() {
  yield takeEvery(actions.ExportActionTypes.EXPORT_REQUEST_AIEU_TOPICS, handleAIFetchEUTopics);
}

function* watchExportNationalTopicsRequest() {
  yield takeEvery(actions.ExportActionTypes.EXPORT_REQUEST_NATIONAL_TOPICS, handleFetchNationalTopics);
}

function* watchExportExcelRequest() {
  yield takeEvery(actions.ExportActionTypes.EXPORT_EXCEL_REQUEST, handleExcelFetch);
}

function* exportSaga() {
  yield all([
    fork(watchExportEUTopicsRequest),
    fork(watchExportNationalTopicsRequest),
    fork(watchExportExcelRequest),
    fork(watchExportAIEUTopicsRequest),
  ]);
}

export default exportSaga;
