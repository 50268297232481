import { action } from 'typesafe-actions';

export interface IExportData {
  export: any[];
}

export const enum ExportActionTypes {
  EXPORT_REQUEST_EU_TOPICS = '@@export/REQUEST_EU_TOPICS',
  EXPORT_REQUEST_AIEU_TOPICS = '@@export/REQUEST_AIEU_TOPICS',
  EXPORT_REQUEST_NATIONAL_TOPICS = '@@export/REQUEST_NATIONAL_TOPICS',
  EXPORT_EXCEL_REQUEST = '@@export/EXCEL_REQUEST',
  EXPORT_EXCEL_REQUEST_SUCCESS = '@@export/EXCEL_REQUEST_SUCCESS',
}

export const exportEUTopicsRequest = (data: IExportData) =>
  action(ExportActionTypes.EXPORT_REQUEST_EU_TOPICS, data);

export const exportAIEUTopicsRequest = (data: IExportData) =>
  action(ExportActionTypes.EXPORT_REQUEST_AIEU_TOPICS, data);

export const exportNationalTopicsRequest = (data: IExportData) =>
  action(ExportActionTypes.EXPORT_REQUEST_EU_TOPICS, data);

export const exportExcelRequest = () => action(ExportActionTypes.EXPORT_EXCEL_REQUEST);

export const exportExcelRequestSuccess = () => action(ExportActionTypes.EXPORT_EXCEL_REQUEST_SUCCESS);
