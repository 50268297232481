import * as React from 'react';
import Filter from './Filters/Filter';
import { FundingResults } from './FundingResults/FundingResults';
import { store } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from '@fluentui/react/lib/Spinner';
import './FundingsPage.css';
import {
  searchRequestFundings,
  deleteSelectedFundings,
  uploadFundingData,
  clearUploadFundingData,
  getFramewokProgramme,
  clearDeleteSelectedFunding,
  setUploadedFile,
} from '../../app/Fundings/actions';
import IconArrowRigh from '../../assets/icon--arrow--right.svg';
import IconArrowLeft from '../../assets/icon--arrow--left2.svg';
import { Dropdown, MessageBar, MessageBarType, TextField, Label } from '@fluentui/react';
import { DefaultButton } from '../ActionButton/ActionButton';
import { Dialogbox } from '../Dialogbox/Dialogbox';
import fundingTemplate from '../../assets/funding_data.xlsx';
import Modal from '../Modal/Modal';
import { closeModal, showModal } from '../../app/Modal/actions';
import UploadFile from '../UploadFile/UploadFile';
import * as auth from '../../app/Auth/Auth';

const Fundings: React.FC = () => {
  const dispatch = useDispatch();
  const isAuthenticated = auth.isAuthenticated();
  const isExporting = useSelector((state: any) => state.funding.isExporting);
  const isDeleting = useSelector((state: any) => state.funding.isDeleting);
  const deletingResponse = useSelector((state: any) => state.funding.deletingResponse);
  const fundingResults = useSelector((state: any) => state.funding.results);
  const loading = useSelector((state: any) => state.funding.loading);
  const currentFrameworkProgramme = useSelector((state: any) => state.funding.frameworkProgramme);
  const currentStatus = useSelector((state: any) => state.funding.status);
  const isUploading = useSelector((state: any) => state.funding.isUploading);
  const uploadingError = useSelector((state: any) => state.funding.uploadingError);
  const uploadingResponse = useSelector((state: any) => state.funding.uploadingResponse);
  const limit = 10;
  const currentPage = useSelector((state: any) => state.funding.currentPage);
  const totalPages = useSelector((state: any) => state.funding.totalPage);
  const isSearchRequestError = useSelector((state: any) => state.funding.isSearchRequestError);
  const isExportRequestError = useSelector((state: any) => state.funding.isExportRequestError);
  const uploadedFile = useSelector((state: any) => state.funding.uploadedFile);
  const [toastMessage, setToastMessage] = React.useState<string | null>(null);
  const [toastType, setToastType] = React.useState<MessageBarType | undefined>(undefined);
  const [selectedReferences, setSelectedReferences] = React.useState<string[]>([]);
  const userEmail: string = localStorage.getItem('userEmail')?.toString() || '';
  const [adminEmails, setAdminEmails] = React.useState<string[]>([
    'sreejith@example.com',
    'turunen.joni.h@gmail.com',
    'joni.turunen@spinverse.com',
    'akseli.makkonen@spinverse.com',
  ]);
  const status: string[] = useSelector((state: any) => state.funding.status);
  const frameworkProgrammes = useSelector((state: any) => state.funding.frameworkProgrammes);
  const [isDialogVisible, setIsDialogVisible] = React.useState(false);
  const [selectedFrameworkProgramme, setSelectedFrameworkProgramme] = React.useState<string>('');
  const dialogContentProps = {
    type: 'delete',
    title: 'Confirm Deletion',
    closeButtonAriaLabel: 'Close',
    subText: `Are you sure you want to delete ${selectedReferences.length} selected funding(s)?`,
  };

  const modelProps = {
    isBlocking: true,
    styles: { main: { maxWidth: 450 } },
  };

  // Track if deletion was just completed
  const [justDeleted, setJustDeleted] = React.useState(false);
  const [transformedFrameworkProgramme, setTransformedFrameworkProgramme] = React.useState<any[]>([]);
  const [newCallProgramme, setNewCallProgramme] = React.useState<string>('');

  const showToast = (message: string, type: MessageBarType, duration = 10000) => {
    setToastMessage(message);
    setToastType(type);
    setTimeout(() => {
      setToastMessage(null);
    }, duration);
  };

  if (!isAuthenticated) {
    window.location.href = '/';
  }

  React.useEffect(() => {
    fetch("https://storage.googleapis.com/auth0_bucket/adminEmail.json?cachebuster=1")
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data) && data.length > 0) {
          setAdminEmails(data);
        }
      })
      .catch((error) => console.error('Error fetching admin emails:', error));
  },[]);

  React.useEffect(() => {
    if (isSearchRequestError) {
      showToast('An error occurred while fetching results. Please refresh', MessageBarType.error, 10000);
    }
    if (isExportRequestError) {
      showToast('An error occured while exporting data. Please refresh', MessageBarType.error, 10000);
    }
    if (uploadingError) {
      showToast('Failed to upload file. Please try again', MessageBarType.error, 10000);
    }
  }, [isExportRequestError, isSearchRequestError, uploadingError]);

  const refetchData = React.useCallback(() => {
    store.dispatch(
      searchRequestFundings({
        frameworkProgramme: currentFrameworkProgramme,
        status: currentStatus,
        limit: limit,
        offset: (currentPage - 1) * limit,
      }),
    );
  }, [currentFrameworkProgramme, currentStatus, limit, currentPage]);

  React.useEffect(() => {
    if (justDeleted && !isDeleting) {
      refetchData();
      setJustDeleted(false);
    }
    if (uploadingError !== null) {
      showToast('Failed to upload file. Please try again', MessageBarType.error);
    }
  }, [isDeleting, justDeleted, currentFrameworkProgramme, currentStatus, currentPage, uploadingError]);

  React.useEffect(() => {
    const transformedFrameworkProgramme = frameworkProgrammes
      .map((item: any) => ({
        key: item.programmeId,
        text: item.programmeName,
      }))
      .sort((a: any, b: any) => a.text.localeCompare(b.text));
    setTransformedFrameworkProgramme(transformedFrameworkProgramme);
  }, [frameworkProgrammes]);

  React.useEffect(() => {
    if (deletingResponse) {
      if (deletingResponse?.statusCode === 200) {
        showToast('Funding deleted successfully', MessageBarType.success);
        refetchData();
        setIsDialogVisible(false);
      } else {
        showToast(
          `Failed to delete funding. Please try again ${deletingResponse?.error}`,
          MessageBarType.error,
        );
        setIsDialogVisible(false);
      }
      store.dispatch(clearDeleteSelectedFunding());
    }
    if (uploadingResponse) {
      if (uploadingResponse?.statusCode === 201) {
        showToast('File uploaded successfully', MessageBarType.success);
        refetchData();
        if (newCallProgramme !== '') {
          store.dispatch(getFramewokProgramme());
        }
        store.dispatch(closeModal());
      } else {
        showToast(
          `Failed to upload file. Please try again ${uploadingResponse?.error}, ${uploadingResponse?.details}`,
          MessageBarType.error,
        );
        store.dispatch(closeModal());
      }
      store.dispatch(clearUploadFundingData());
      setNewCallProgramme('');
    }
  }, [uploadingResponse, deletingResponse]);

  const handlePageChange = React.useCallback(
    (newPage: number) => {
      if (newPage < 1 || newPage > totalPages) {
        return;
      }
      const offset: number = (newPage - 1) * limit;
      store.dispatch(
        searchRequestFundings({
          frameworkProgramme: currentFrameworkProgramme,
          status: currentStatus,
          limit: limit,
          offset: offset,
        }),
      );
    },
    [currentFrameworkProgramme, currentStatus, limit, totalPages],
  );

  const handleToggleReference = (reference: string, isChecked: boolean) => {
    setSelectedReferences((prev) =>
      isChecked ? [...prev, reference] : prev.filter((ref) => ref !== reference),
    );
  };

  const handleFileUpload = async (file: File, frameworkProgramme: string, newCallProgramme: string) => {
    if (file) {
      const formData = new FormData();
      formData.append('files', file);
      formData.append('frameworkProgramme', JSON.stringify([frameworkProgramme]));
      formData.append('newFrameworkProgramme', newCallProgramme);
      store.dispatch(uploadFundingData({ formData }));
      setSelectedFrameworkProgramme('');
      setNewCallProgramme('');
      store.dispatch(setUploadedFile(null));
    }
  };

  React.useEffect(() => {
    dispatch(getFramewokProgramme());
  }, [dispatch]);

  const handleDeleteFundigClick = () => {
    setIsDialogVisible(true);
  };
  const handleDeleteFundingClose = () => {
    setIsDialogVisible(false);
  };

  const handleDeleteSelected = () => {
    store.dispatch(deleteSelectedFundings({ references: selectedReferences }));
    setJustDeleted(true);
    setSelectedReferences([]);
  };

  const handleDownloadTemplate = () => {
    const link = document.createElement('a');
    link.href = fundingTemplate;
    link.download = 'funding_data.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSelectAll = () => {
    const allReferences = fundingResults.map((result: any) => result.reference);
    setSelectedReferences(allReferences);
  };

  const handleClearAll = () => {
    setSelectedReferences([]);
  };

  return (
    <div className="search-page">
      <Modal>
        <div className="model-content-container">
          <div className="upload-download-container">
            <UploadFile acceptTypes=".csv, .xlsx" />
          </div>
          <div className="add-select-programe-container">
            <DefaultButton text="Download template" handleClick={handleDownloadTemplate} />
            <Label htmlFor="select-programme">Select call programme</Label>
            <Dropdown
              placeholder="Plaease select a programme"
              ariaLabel="Please select a programme"
              options={transformedFrameworkProgramme}
              dropdownWidth="auto"
              onChange={(event, Option) => setSelectedFrameworkProgramme(Option?.key.toString() || '')}
            />
            <TextField
              label="Add new call programme"
              placeholder="Enter new call programme"
              onChange={(event, newValue) => setNewCallProgramme(newValue || '')}
            />
            <DefaultButton
              text="Upload"
              handleClick={() => handleFileUpload(uploadedFile, selectedFrameworkProgramme, newCallProgramme)}
            />
          </div>
        </div>
      </Modal>
      {toastMessage && (
        <div className="message-bar">
          <MessageBar
            messageBarType={toastType}
            isMultiline={true}
            onDismiss={() => setToastMessage(null)}
            dismissButtonAriaLabel="Close"
          >
            {toastMessage}
          </MessageBar>
        </div>
      )}
      {/* <input
        type="file"
        accept=".csv, .xlsx"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="file-upload"
      /> */}
      <div className="manipulate-funding-container">
        {adminEmails.includes(userEmail) && (
          <>
            <div>
              {!isUploading ? (
                <DefaultButton text="Upload csv/xlsx file" handleClick={() => store.dispatch(showModal())} />
              ) : (
                <Spinner />
              )}
            </div>
            {selectedReferences.length > 0 && (
              <div>
                <DefaultButton text="Delete selected" handleClick={handleDeleteFundigClick} />
              </div>
            )}
          </>
        )}
      </div>
      {adminEmails.includes(userEmail) && status.includes('31094504') && (
        <div className="manipulate-select-container">
          <>
            <div>
              <DefaultButton text="Select all" handleClick={handleSelectAll} />
            </div>
            <div>
              <DefaultButton text="Clear all" handleClick={handleClearAll} />
            </div>
          </>
        </div>
      )}
      <Dialogbox
        dialogContentProps={dialogContentProps}
        modalProps={modelProps}
        hideDialog={!isDialogVisible}
        onDismiss={handleDeleteFundingClose}
        pbOnClick={handleDeleteSelected}
        pbText="Delete"
        dbOnClick={handleDeleteFundingClose}
        dbText="Cancel"
      />
      <div className="container result">
        <div className="aside">
          <div className="filter-sticky-container">
            <Filter />
          </div>
        </div>
        <div
          className="main"
          style={isExporting || isUploading ? { opacity: 0.75, pointerEvents: 'none' } : {}}
        >
          <div className="search-results--container">
            <div className="search-results--content">
              {loading && !isSearchRequestError && (
                <div className="spinner-container">
                  <Spinner />
                </div>
              )}
              {!loading && !isSearchRequestError && fundingResults.length > 0
                ? fundingResults.map((result: any, index: number) => (
                    <FundingResults
                      key={`${result.reference}`}
                      index={index}
                      data={result}
                      onToggle={handleToggleReference}
                      isSelected={selectedReferences.includes(result.reference)}
                      showCheckbox={adminEmails.includes(userEmail)}
                    />
                  ))
                : !loading && <p className="no-data">No data to show</p>}
            </div>
          </div>
          {!loading && !isSearchRequestError && fundingResults.length > 0 && (
            <div className="pagination">
              <div className="pagination-button">
                <img src={IconArrowLeft} alt="previous" onClick={() => handlePageChange(currentPage - 1)} />
              </div>
              <span>
                Page {currentPage} of {totalPages}
              </span>
              <div className="pagination-button">
                <img src={IconArrowRigh} alt="next" onClick={() => handlePageChange(currentPage + 1)} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const FundingsPage = Fundings;
